
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { ValidationRules } from '../../public/models/validationRules';
import { StoresModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import ChangePasswordModal from '@/components/modals/changePasswordModal.vue';
import { SidebarMixin } from '@/mixins/sidebarMixin';
import AuthoriseSocialChannelsModal from '@/components/modals/authoriseSocialChannelsModal.vue';
import { SocialChannels } from '@/models/posts/socialChannels';

const storesModule = getModule(StoresModule);

@Component({
  components: { ChangePasswordModal, AuthoriseSocialChannelsModal },
  mixins: [SidebarMixin],
})
export default class SettingsIndex extends LoggedInComponentBase {
  public rules: ValidationRules = new ValidationRules();
  public states: string[] = ['ACT', 'NSW', 'NT', 'QLD', 'TAS', 'VIC', 'WA'];

  // Notification
  public showNotification: boolean = false;
  public notificationMessage: string = '';
  public notificationColour: string = 'green';
  public notificationTimeout: string = '2500';
  public ssoLink: string = '';
  public hideStoreDetails: boolean = !this.userModule.isViewingSingleStore;

  public get currentStore() {
    return this.userModule.currentStore;
  }

  public get addressLine1() {
    return storesModule.storeDetails?.addressLine1 ?? '';
  }

  public set addressLine1(addressLine1: string) {
    storesModule.changeStoreDetails({
      key: 'addressLine1',
      value: addressLine1,
    });
  }

  public get addressLine2() {
    return storesModule.storeDetails?.addressLine2 ?? '';
  }

  public set addressLine2(addressLine2: string) {
    storesModule.changeStoreDetails({
      key: 'addressLine2',
      value: addressLine2,
    });
  }

  public get currentlyAuthorisedText(): string {
    const channelsNotAuthorised = SocialChannels.filter(
      (channel) => !this.storeChannels.includes(channel)
    );

    if (this.storeChannels.length > 0) {
      return `<p>Your store is currently authorised for: <strong>${this.storeChannels}</strong></p><p>You are not yet authorised for: <strong>${channelsNotAuthorised}</strong>`;
    } else {
      return 'Your store has not authorised any social channels yet.';
    }
  }

  public get storeChannels(): string[] {
    return storesModule.storeChannels;
  }

  public get displayName() {
    return this.userModule.currentUser
      ? this.userModule.currentUser.displayName
      : '';
  }

  public set displayName(name: string) {
    this.userModule.setDisplayName(name);
  }

  public get storeDetails() {
    return storesModule.storeDetails;
  }

  public get storeEmail() {
    return storesModule.storeDetails?.email ?? '';
  }

  public set storeEmail(email: string) {
    storesModule.changeStoreDetails({
      key: 'email',
      value: email,
    });
  }

  public get storePhone() {
    return storesModule.storeDetails?.phone ?? '';
  }

  public set storePhone(phone: string) {
    storesModule.changeStoreDetails({
      key: 'phone',
      value: phone,
    });
  }

  public get storePostcode() {
    return storesModule.storeDetails ? storesModule.storeDetails.postcode : '';
  }

  public set storePostcode(postcode: string) {
    storesModule.changeStoreDetails({
      key: 'postcode',
      value: postcode,
    });
  }

  public get storeState() {
    return storesModule.storeDetails ? storesModule.storeDetails.state : '';
  }

  public set storeState(state: string) {
    storesModule.changeStoreDetails({
      key: 'state',
      value: state,
    });
  }

  public get storeTitle() {
    return storesModule.storeDetails ? storesModule.storeDetails.title : '';
  }

  public set storeTitle(title: string) {
    storesModule.changeStoreDetails({
      key: 'title',
      value: title,
    });
  }

  public get suburb() {
    return storesModule.storeDetails?.suburb ?? '';
  }

  public set suburb(suburb: string) {
    storesModule.changeStoreDetails({
      key: 'suburb',
      value: suburb,
    });
  }

  public get userEmail() {
    return this.userModule.currentUser?.email;
  }

  async created() {
    if (this.userModule.isViewingSingleStore) {
      await storesModule.getStoreDetails(this.userModule.currentStore.id);
      await storesModule.updateSocialChannels(this.userModule.currentStore.id);
    }
  }

  public changePassword() {
    this.userModule.showChangePasswordModal(true);
  }

  public resetNotification() {
    this.notificationTimeout = '2500';
    this.showNotification = false;
    this.notificationColour = 'success';
  }

  public showAuthoriseSocialModal() {
    this.storesModule.toggleAuthoriseSocialChannelsModal(true);
  }

  public async formStoreDetailsSubmit(form: VForm) {
    try {
      this.resetNotification();

      if (form.validate()) {
        if (this.storeDetails) {
          await storesModule.updateStoreDetails(this.storeDetails);

          this.notificationMessage = 'Store has been updated.';
          this.showNotification = true;
        }
      }
    } catch (err) {
      this.notificationMessage = 'There was an error updating the store.';
      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.showNotification = true;
    }
  }

  public async formProfileDetailsSubmit(form: VForm) {
    try {
      this.resetNotification();

      if (form.validate()) {
        await this.userModule.updateUserProfile({
          displayName: this.displayName,
        });

        this.notificationMessage = 'Your details have been updated.';
        this.showNotification = true;
      }
    } catch (err) {
      this.notificationMessage = 'There was an error updating your profile.';
      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.showNotification = true;
    }
  }

  @Watch('currentStore')
  public async onStoreChanged() {
    if (this.userModule.isViewingSingleStore) {
      await storesModule.getStoreDetails(this.userModule.currentStore.id);
      this.hideStoreDetails = false;
    } else {
      this.hideStoreDetails = true;
    }
  }
}
