export class ValidationRules {
  public required: ValidationRule = value => !!value || 'Required.';

  public usernameLength: ValidationRule = value =>
    (value.length >= 3 && value.length <= 50) ||
    'Required between 3-50 characters';

  public passwordLength: ValidationRule = value =>
    (value.length >= 8 && value.length <= 255) ||
    'Required between 8-255 characters';

  public passwordComplexity: ValidationRule = value => {
    const pattern = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w!@#:,<>;$%\\|^&?/*)(=+_{}[\]]{8,}$/;
    const test = pattern.test(value);
    return test
      ? test
      : 'Must be at least 8 characters. Min 1 lowercase letter, min 1 uppercase letter, min 1 digit. Special characters permitted.';
  };

  public email: ValidationRule = value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Invalid e-mail.';
  };

  public phoneNumber: ValidationRule = value => {
    const patterns = {
      strong: /^0[0-8]\d{8}$/g,
      loose: /^[0-9\s]*$/,
      matchBE: /^(?=.*[0-9])[- +()0-9]+$/
    }
    return patterns.matchBE.test(value) || 'Invalid phone number';
  };
}
